import { graphql, PageProps } from 'gatsby';
import { Box } from '@chakra-ui/react';
import SEO from '../components/SEO';
import { SanityPage } from '../../graphql-types';
import Content from '../components/Content';

interface IndexPageProps extends PageProps {
    data: { page: SanityPage };
}

export default function IndexPage({ data }: IndexPageProps) {
    return (
        <div>
            <SEO />
            <Box pb={20}>
                <Content data={data.page?.content} />
            </Box>
        </div>
    );
}

export const query = graphql`
    query HomePageQuery($language: String!) {
        page: sanityPage(_id: { eq: "home-page" }) {
            ...SanityPageFragment
        }
        locales: allLocale(filter: { language: { eq: $language } }) {
            ...LocaleFragment
        }
    }
`;
